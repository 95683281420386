import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';

type SSRRect = {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
};
const EmptySSRRect: SSRRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0
};

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const useRect = (ref: RefObject<HTMLDivElement>) => {
  const [rect, setRect] = useState<SSRRect>(
    ref?.current?.getBoundingClientRect() || EmptySSRRect
  );

  const handleResize = useCallback(() => {
    if (!ref.current) return;
    setRect(ref?.current?.getBoundingClientRect()); // Update client rect
  }, [ref]);

  const resizeObserver = new ResizeObserver(() => handleResize());

  useIsomorphicLayoutEffect(() => {
    const element = ref.current;
    if (!element) return;

    // handleResize();

    if (typeof ResizeObserver === 'function') {
      resizeObserver.observe(element);
      return () => {
        if (!resizeObserver) return;
        resizeObserver.disconnect();
      };
    } else {
      window.addEventListener('resize', handleResize); // Browser support, remove freely
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [ref, resizeObserver]);

  return rect;
};

const useScroll = () => {
  const [lastScrollTop, setLastScrollTop] = useState<number>(0);
  const [scrollY, setScrollY] = useState<number>(0);
  const [scrollX, setScrollX] = useState<number>(0);
  const [scrollDirection, setScrollDirection] = useState<
    'down' | 'up' | undefined
  >();

  const listener = () => {
    const offset =
      typeof window === 'undefined' || !window.document
        ? EmptySSRRect
        : document?.body?.getBoundingClientRect();

    setScrollY(-offset.top);
    setScrollX(offset.left);
    setScrollDirection(lastScrollTop > -offset.top ? 'down' : 'up');

    setLastScrollTop(-offset.top);
  };

  useEffect(() => {
    window?.addEventListener('scroll', listener);
    return () => {
      window?.removeEventListener('scroll', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    scrollY,
    scrollX,
    scrollDirection
  };
};

export { useScroll, useRect };
